package de.comhix.anime.client

import de.comhix.anime.client.component.Navigation
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import web.cssom.ClassName

/**
 * @author Benjamin Beeker
 */

external interface LayoutProps : Props {
    var title: String
    var component: FC<Props>
}

val Layout = FC<LayoutProps> {
    div {
        className = ClassName("root-container")

        h1 { +it.title }

        div {
            className = ClassName("navigation")
            Navigation()
        }

        div {
            className = ClassName("content")
            it.component()
        }
    }
}