package de.comhix.anime.client.api

import de.comhix.anime.sharedCode.api.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.reflect.*
import kotlinx.coroutines.flow.Flow
import de.comhix.anime.sharedCode.api.ReadonlyRestApi as ReadonlyRestApiInterface
import de.comhix.anime.sharedCode.api.RestApi as RestApiInterface


/**
 * @author Benjamin Beeker
 */
data class ApiClient(private val baseUrl: String, private val token: String) {
    internal val httpClient: HttpClient = HttpClient {
        install(ContentNegotiation) {
            json()
        }
        defaultRequest {
            url(baseUrl)
            header("Authorization", "Bearer $token")
        }
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.ALL
        }
        expectSuccess = true
    }

    val transmission: TransmissionApi =
        object : ReadonlyRestApi<Torrent>(typeInfo<Torrent>(), typeInfo<List<Torrent>>()), TransmissionApi {
            override suspend fun addTorrent(magnetLink: String): Torrent = httpClient.post(baseUrl) {
                parameter("magnet-link", magnetLink)
            }.body()

            override suspend fun delete(id: Int) {
                httpClient.delete("$baseUrl/$id")
            }
        }

    val source: SourceApi = object : RestApi<Source>(typeInfo<Source>(), typeInfo<List<Source>>()), SourceApi {}

    val file: FileApi = object : FileApi {
        override suspend fun getList(): List<File> = httpClient.get(baseUrl).body()
        override suspend fun observeList(): Flow<File> {
            TODO("Not yet implemented")
        }
    }

    private abstract inner class ReadonlyRestApi<T : Entity>(
        protected val entityType: TypeInfo,
        protected val listType: TypeInfo
    ) :
        ReadonlyRestApiInterface<T> {

        override suspend fun getList(): List<T> = httpClient.get(baseUrl).body(listType)

        override suspend fun get(id: Int): T = httpClient.get("$baseUrl/$id").body(entityType)
    }

    private abstract inner class RestApi<T : Entity>(entityType: TypeInfo, listType: TypeInfo) :
        ReadonlyRestApi<T>(entityType, listType),
        RestApiInterface<T> {
        override suspend fun create(entity: T): T =
            httpClient.post(baseUrl) {
                setBody(entity, entityType)
                contentType(ContentType.Application.Json)
            }.body(entityType)

        override suspend fun update(entity: T): T =
            httpClient.put("$baseUrl/${entity.id}") {
                setBody(entity, entityType)
                contentType(ContentType.Application.Json)
            }.body(entityType)

        override suspend fun delete(id: Int) {
            httpClient.delete("$baseUrl/$id")
        }
    }

}
