package de.comhix.anime.client

import de.comhix.anime.client.api.ApiClient
import de.comhix.anime.client.config.ApiHost
import de.comhix.anime.client.page.Download
import de.comhix.anime.client.page.Info
import de.comhix.anime.client.page.Source
import de.comhix.anime.client.page.SourceList
import kotlinx.coroutines.MainScope
import react.FC
import react.Fragment
import react.Props
import react.create
import react.router.Navigate
import react.router.RouteObject
import react.router.dom.createHashRouter

/**
 * @author Benjamin Beeker
 */

val mainScope = MainScope()

val client = ApiClient(ApiHost, "eRQgNZat3YpfW2AI7y3yRHvTQYGTgYkz7Pv0dPpDHFjaqsQw-3")

val App = FC<Props> {
    createHashRouter(
        arrayOf(
            RouteObject(path = "", element = Fragment.create {
                Navigate {
                    to = "download"
                }
            }),

            RouteObject(
                path = "download",
                element = Fragment.create {
                    Layout {
                        title = "Download"
                        component = Download
                    }
                }

            ),
            RouteObject(
                path = "sources",
                element = Fragment.create {
                    Layout {
                        title = "Sources"
                        component = SourceList
                    }
                }
            ),
            RouteObject(
                path = "sources/:id",

                element = Fragment.create {
                    Layout {
                        title = "Source"
                        component = Source
                    }
                }
            ),
            RouteObject(
                path = "info",
                element = Fragment.create {
                    Layout {
                        title = "Info"
                        component = Info
                    }
                }
            ),
            RouteObject(
                path = "*"
                        + "Not found"
            )
        )
    )
}