package de.comhix.anime.client.component

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.router.dom.Link
import web.cssom.ClassName

/**
 * @author Benjamin Beeker
 */

val Navigation = FC<Props> {
    ul {
        className = ClassName("horizontal")
        li {
            Link {
                to = "/download"

                + "Download"
            }
        }
        li {
            Link {
                to = "/sources"

                + "Sources"
            }
        }
        li {
            Link {
                to = "/info"

                + "Info"
            }
        }
    }
}