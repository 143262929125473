package de.comhix.anime.client.page

import de.comhix.anime.client.client
import de.comhix.anime.client.mainScope
import de.comhix.anime.client.utils.format
import de.comhix.anime.sharedCode.api.File
import io.ktor.http.*
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.useEffectOnce
import react.useState
import web.cssom.ClassName

/**
 * @author Benjamin Beeker
 */
val Download = FC<Props>(displayName = "Download") {
    var files: List<File> by useState(emptyList())

    useEffectOnce {
        mainScope.launch {
            files = client.file.getList()
        }
    }

    table {
        className = ClassName("download-list table")
        thead {
            className = ClassName("download-list header")
            td {
                +"File"
            }
            td {
                +"SHA256"
            }
            td {
                +"Size"
            }
            td {
                +"Last modified"
            }
        }

        files.forEach {
            tr {
                className = ClassName("download-list")
                td {
                    a {
                        href = "downloads/${it.name.encodeURLPath()}"
                        +it.name
                    }
                }
                td {
                    +(it.sha256.substring(0, 8))
                }
                td {
                    +"${it.size}"
                }
                td {
                    +it.lastModified.format()
                }
            }
        }
    }
}