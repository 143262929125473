package de.comhix.anime.client.component

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import web.cssom.ClassName
import kotlin.reflect.KProperty0

/**
 * @author Benjamin Beeker
 */
external interface InputProps : Props {
    var field: KProperty0<String>
    var label: String?
}


val Input = FC<InputProps> {
    div {
        className = ClassName("input container")
        label {
            className = ClassName("input label")
            +(it.label ?: it.field.name.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() })
        }
        input {
            className = ClassName("input value")
            name = it.field.name
            value = it.field.get()
        }
    }
}