package de.comhix.anime.client.page

import de.comhix.anime.client.client
import de.comhix.anime.client.mainScope
import de.comhix.anime.sharedCode.api.Source
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.router.dom.Link
import react.useEffectOnce
import react.useState

/**
 * @author Benjamin Beeker
 */
val SourceList = FC<Props> {
    var sources: List<Source> by useState(emptyList())

    useEffectOnce {
        mainScope.launch {
            sources = client.source.getList()
        }
    }

    ul {
        sources.forEach {
            li {
                Link {
                    to = it.id.toString()
                    +it.name
                }
            }
        }
    }
}