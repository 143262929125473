package de.comhix.anime.client.page

import de.comhix.anime.client.config.ApiHost
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span

/**
 * @author Benjamin Beeker
 */
val Info = FC<Props> {
    div {
        span {
            +ApiHost
        }
    }
}