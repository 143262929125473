import de.comhix.anime.client.App
import de.comhix.anime.sharedCode.logger.logger
import react.create
import react.dom.client.createRoot
import web.dom.document

/**
 * @author Benjamin Beeker
 */
fun main() {
    logger("main").info { "starting up client" }

    val container = document.getElementById("root") ?: error("Couldn't find root container!")
    createRoot(container).render(App.create())
}