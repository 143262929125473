package de.comhix.anime.client.page

import de.comhix.anime.client.client
import de.comhix.anime.client.component.Input
import de.comhix.anime.client.mainScope
import de.comhix.anime.sharedCode.api.Source
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnce
import react.useState
import web.cssom.ClassName

/**
 * @author Benjamin Beeker
 */

val Source = FC<Props> {
    val params = useParams()
    val id = params["id"]!!.toInt()

    var source: Source? by useState()
    useEffectOnce {
        mainScope.launch {

            source = client.source.get(id)
        }
    }

    source?.let {
        div {
            className = ClassName("formular")
            Input {
                field = it::name
            }
            Input {
                field = it::rssFeedUrl
                label = "RSS Feed"
            }
        }
    } ?: run {
        +"Source $id not found"
    }
}