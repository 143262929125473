package de.comhix.anime.client.utils

import kotlinx.datetime.*
import kotlinx.datetime.format.DateTimeFormat
import kotlinx.datetime.format.char

/**
 * @author Benjamin Beeker
 */
fun Instant.format(format: DateTimeFormat<LocalDateTime> = dateTimeFormatter): String =
    this.toLocalDateTime(TimeZone.currentSystemDefault()).format(format)

val dateFormatter = LocalDate.Format {
    dayOfMonth()
    char('.')
    monthNumber()
    char('.')
    year()
}

val timeFormatter = LocalTime.Format {
    hour()
    char(':')
    minute()
    char(':')
    second()
}

val dateTimeFormatter = LocalDateTime.Format {
    date(dateFormatter)
    char(' ')
    time(timeFormatter)
}
